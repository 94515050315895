<template>
<div>
  <navbar title="جزئیات محصول"/>
  <v-main class="pt-15 pb-15 d-flex flex-column justify-center">
    <v-container fluid>
    <v-skeleton-loader
    v-if="!product"
      v-bind="attrs"
      type="image, list-item-three-line,list-item-three-line,divider,list-item-three-line,list-item-three-line,list-item-two-line" max-width="100%" height="702"
    ></v-skeleton-loader>
    <div v-if="product">
      <v-card class="card pa-3">
        <slider :gallery='product.gallery' :images ='product.image'/>
        <detail :product= 'product' class="pt-1"/>
      </v-card>
      <gray :product= 'product'/>
    </div>
    </v-container>
    <product :options='options' v-if="similar.length > 1" />
    <v-container fluid>
    <tab v-if="product" :product= 'product' class="mt-4"/>
    </v-container>
  </v-main>
  <action/>
</div>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import slider from './components/slider/productDetailSlider'
import detail from './components/cards/productDetailCard'
import gray from './components/cards/productDetailGrayCard'
import tab from './components/tabMenu/productDetailTab'
import store from '@/store'
import product from './components/slider/similarProduct'
import action from '../home/components/navbar/actionBar'
import { viewProducts, similarProduct, viewbasket_products } from '../Basket/models/Basket'
import { comments_product } from './models/productsDetail'
import { mapState } from 'vuex'
export default {
  props: ['productsID'],
    components: {
      navbar,
      slider,
      detail,
      gray,
      tab,
      product,
      action
    },
    data: () => ({
      attrs: {
        boilerplate: false,
        elevation: 0,
      },
      options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: false,
      autoplay: true,
      drag: true,
      pagination: false,
      type   : 'loop',
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl',
      breakpoints: {
        350: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 1.2,
        },
        375: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 1.5,
        },
        380: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        420: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        500: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2.2,
        },
        560: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        669: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 3,
        },
        700:{
          width  : '100%',
          perPage:3,
          gap    : '0.5rem', 
        },
        770:{
          width  : 750,
          perPage:3,
          gap    : '0.5rem', 
        }
      },
    },
    }),
    created () {
      scrollTo(0,0)
      viewProducts(this.$props.productsID)
      similarProduct(this.$props.productsID)
      comments_product(this.$props.productsID)
      const basket = localStorage.getItem('basket')
        if (basket) {
          const time = new Date()
          const now = time.getTime()
          const old_time_basket = parseInt(localStorage.getItem('time_basket'))
          if ((old_time_basket + 86400000) < now ) {
            localStorage.removeItem('basket')
            viewbasket_products()
          } else {
          store.dispatch('basket_local', JSON.parse(basket))
          }
        } else {
          viewbasket_products()
        }
    },
    watch: {
      productsID: {
        handler: function () {
          const id = this.$props.productsID
          location.reload()
          viewProducts(id)
          comments_product(id)
          similarProduct(id)
        }
      }
    },
    computed: {
      ...mapState({
        product:state => state.basket.product_detail,
        similar: state => state.basket.similarProducts,
        loading: state => state.loading_page
      })
    }
}
</script>