<template>
  <div>
    <v-card class="card">
     <v-tabs right fixed-tabs v-model="tab" class="pb-2">
      <v-tab>نظرات</v-tab>
      <v-tab>معرفی محصول</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="background">
      <v-tab-item class="">
        <comment/>
        <v-card class="product-detail-card">
          <v-row class="d-flex pa-3">
          <span class="medium-font mr-2">نظرتان درمورد این کالا را با دیگران به اشتراک بگذارید</span>
          </v-row>
          <v-row class="pa-3 ma-1">
          <v-spacer></v-spacer>
          <v-btn @click= 'add_comment' class="accent primaryText--text button" small>ثبت نظر</v-btn>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item class="background" v-if="product.review_description">
        <v-card class="product-detail-card">
          <v-card-text class="medium-font" v-html="product.review_description">
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="!product.review_description">
        <div class="d-flex flex-column align-center pa-3">
        <v-img class="my-3" width="60" src="@/assets/icon/description.svg"></v-img>
        <span class="medium-font">موردی وجود ندارد</span>
        </div>
      </v-tab-item>
      
    </v-tabs-items>
  </v-card>
  </div>
</template>
<script>
import router from '@/router'
import comment from '../cards/productCommentCard'
export default {
  props: ['product'],
  data: () => ({
    tab: null,
  }),
  components: {
    comment
  },
  methods: {
    add_comment () {
      router.push(`/add_comment/${this.$props.product.id}`)
    }
  }
}
</script>